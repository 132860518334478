
import { ROUTE_CONSTANTS } from 'src/constants/AppRoutes'
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DashboardCustomizeOutlined from '@mui/icons-material/DashboardCustomizeOutlined';
import PeopleAltOutlined from '@mui/icons-material/PeopleAltOutlined';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import LayersIcon from '@mui/icons-material/Layers';
import PinDropIcon from '@mui/icons-material/PinDrop';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import Groups3Icon from '@mui/icons-material/Groups3';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { ApartmentOutlined, CarRepairOutlined, MessageOutlined } from '@mui/icons-material';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
// ***** user routes ***** 
const adminsRoute = {
  categorySlug: 'masters',
  name: 'Users',
  link: ROUTE_CONSTANTS.DASHBOARD.MASTERS.USERS.ADMINS.ROOT.ABSOLUTE,
  badgeTooltip: 'Admin List',
  icon: PeopleAltOutlined,
  permissionKey: 'ADMIN:READ'
}
const customerRoute = {
  categorySlug: 'masters',
  name: 'Customers',
  link: ROUTE_CONSTANTS.DASHBOARD.MASTERS.CUSTOMERS.ROOT.ABSOLUTE,
  badgeTooltip: 'Customer List',
  icon: PeopleAltIcon,
  permissionKey: 'CUSTOMER:READ'
}

const bookingsRoute = {
  categorySlug: 'masters',
  name: 'Bookings',
  link: ROUTE_CONSTANTS.DASHBOARD.MASTERS.BOOKING.ROOT.ABSOLUTE,
  badgeTooltip: 'Bookings',
  icon: BookOnlineIcon,
  permissionKey: 'BOOKING:READ'
}
const carManufactorerRoute = {
  categorySlug: 'masters',
  name: 'Car Manufacturers',
  link: ROUTE_CONSTANTS.DASHBOARD.MASTERS.CAR_MANUFACTURER.ROOT.ABSOLUTE,
  badgeTooltip: 'Car Manufacturer List',
  icon: PrecisionManufacturingIcon,
  permissionKey: 'CAR_MANUFACTURER:READ'
}
const carModelRoute = {
  categorySlug: 'masters',
  name: 'Car Models',
  link: ROUTE_CONSTANTS.DASHBOARD.MASTERS.CAR_MODEL.ROOT.ABSOLUTE,
  badgeTooltip: 'Car Models List',
  icon: DirectionsCarFilledIcon,
  permissionKey: 'CAR_MODEL:READ'
}
const parkingLocationRoute = {
  categorySlug: 'masters',
  name: 'Branches',
  link: ROUTE_CONSTANTS.DASHBOARD.MASTERS.PARKING_LOCATION.ROOT.ABSOLUTE,
  badgeTooltip: 'Branches',
  icon: PinDropIcon,
  permissionKey: 'PARKING_LOCATION:READ'
}
const parkingFloorRoute = {
  categorySlug: 'masters',
  name: 'Parking Floor',
  link: ROUTE_CONSTANTS.DASHBOARD.MASTERS.PARKING_FLOOR.ROOT.ABSOLUTE,
  badgeTooltip: 'Parking Floor List',
  icon: LayersIcon,
  permissionKey: 'PARKING_FLOOR:READ'
}
const parkingBlockRoute = {
  categorySlug: 'masters',
  name: 'Parking Block',
  link: ROUTE_CONSTANTS.DASHBOARD.MASTERS.PARKING_BLOCK.ROOT.ABSOLUTE,
  badgeTooltip: 'Parking Block List',
  icon: ViewDayIcon,
  permissionKey: 'PARKING_BLOCK:READ'
}
const keyTagRoute = {
  categorySlug: 'masters',
  name: 'Key Tag',
  link: ROUTE_CONSTANTS.DASHBOARD.MASTERS.KEY_TAG.ROOT.ABSOLUTE,
  badgeTooltip: 'Key Tag List',
  icon: LocalOfferIcon,
  permissionKey: 'KEY_TAG:READ'
}
const SubscriptionRoute = {
  categorySlug: 'masters',
  name: 'Subscriptions',
  link: ROUTE_CONSTANTS.DASHBOARD.MASTERS.SUBSCRIPTION.ROOT.ABSOLUTE,
  badgeTooltip: 'Permissions',
  icon: CardMembershipIcon,
  permissionKey: 'SUBSCRIPTION:READ'
}
const AdvertisementRoute = {
  categorySlug: 'masters',
  name: 'Advertisements',
  link: ROUTE_CONSTANTS.DASHBOARD.MASTERS.ADVERTISEMENT.ROOT.ABSOLUTE,
  badgeTooltip: 'Permissions',
  icon: AdUnitsIcon,
  permissionKey: 'BANNER:READ'
}
const companiesRoute = {
  categorySlug: 'masters',
  name: 'Companies',
  link: ROUTE_CONSTANTS.DASHBOARD.MASTERS.COMPANY.ROOT.ABSOLUTE,
  badgeTooltip: 'Permissions',
  icon: ApartmentOutlined,
  permissionKey: 'COMPANY:READ'
}
const servicesRoute = {
  categorySlug: 'masters',
  name: 'Services',
  link: ROUTE_CONSTANTS.DASHBOARD.MASTERS.SERVICE.ROOT.ABSOLUTE,
  badgeTooltip: 'Permissions',
  icon: SettingsSuggestIcon,
  permissionKey: 'SERVICE:READ'
}

// ***** Reports routes *****
const landLordsRoute = {
  categorySlug: 'reports',
  name: 'LandLords',
  link: ROUTE_CONSTANTS.DASHBOARD.REPORTS.LANDLORDS.ROOT.ABSOLUTE,
  badgeTooltip: 'LandLords Report',
  icon: AssessmentIcon,
  permissionKey: 'REPORT:LANDLORD'
}
const checkInOutRoute = {
  categorySlug: 'reports',
  name: 'Check-in Check-out',
  link: ROUTE_CONSTANTS.DASHBOARD.REPORTS.CHECKINOUTREPORT.ROOT.ABSOLUTE,
  badgeTooltip: 'Check-in Check-out report',
  icon: AssessmentIcon,
  permissionKey: 'REPORT:CHECKINOUT'
}
const incomeReportRoute = {
  categorySlug: 'reports',
  name: 'Income',
  link: ROUTE_CONSTANTS.DASHBOARD.REPORTS.INCOMEREPORT.ROOT.ABSOLUTE,
  badgeTooltip: 'Income report',
  icon: AssessmentIcon,
  permissionKey: 'REPORT:INCOME'
}
const CloseShiftReportRoute = {
  categorySlug: 'reports',
  name: 'Shift Closing',
  link: ROUTE_CONSTANTS.DASHBOARD.REPORTS.CLOSESHIFTREPORT.ROOT.ABSOLUTE,
  badgeTooltip: 'Shift Closing',
  icon: AssessmentIcon,
  permissionKey: 'REPORT:CLOSING'
}
const DriverReportRoute = {
  categorySlug: 'reports',
  name: 'Drivers',
  link: ROUTE_CONSTANTS.DASHBOARD.REPORTS.DRIVER_REPORTS.ROOT.ABSOLUTE,
  badgeTooltip: 'Drivers Report',
  icon: AssessmentIcon,
  permissionKey: 'REPORT:DRIVERS'
}
const KeyTagReportRoute = {
  categorySlug: 'reports',
  name: 'Key Tag',
  link: ROUTE_CONSTANTS.DASHBOARD.REPORTS.KEYTAG_REPORTS.ROOT.ABSOLUTE,
  badgeTooltip: 'Key Tag Report',
  icon: AssessmentIcon,
  permissionKey: 'REPORT:KEYTAG'
}
const HourWiseReportRoute = {
  categorySlug: 'reports',
  name: 'Hourwise',
  link: ROUTE_CONSTANTS.DASHBOARD.REPORTS.HOURWISE_REPORTS.ROOT.ABSOLUTE,
  badgeTooltip: 'Hourwise Report',
  icon: AssessmentIcon,
  permissionKey: 'REPORT:HOURWISE'
}
const IncomeSummaryReportRoute = {
  categorySlug: 'reports',
  name: 'Income Summary',
  link: ROUTE_CONSTANTS.DASHBOARD.REPORTS.INCOMESUMMARY_REPORTS.ROOT.ABSOLUTE,
  badgeTooltip: 'Income Summary Report',
  icon: AssessmentIcon,
  permissionKey: 'REPORT:INCOMESUMMARY'
}
const VoucherReportRoute = {
  categorySlug: 'reports',
  name: 'Voucher',
  link: ROUTE_CONSTANTS.DASHBOARD.REPORTS.VOUCHER_REPORTS.ROOT.ABSOLUTE,
  badgeTooltip: 'Voucher Report',
  icon: AssessmentIcon,
  permissionKey: 'REPORT:VOUCHER'
}
const ServiceReportRoute = {
  categorySlug: 'reports',
  name: 'Service',
  link: ROUTE_CONSTANTS.DASHBOARD.REPORTS.SERVICE_REPORTS.ROOT.ABSOLUTE,
  badgeTooltip: 'Service Report',
  icon: AssessmentIcon,
  permissionKey: 'REPORT:SERVICE'
}
const FeedbackReportRoute = {
  categorySlug: 'reports',
  name: 'Feedback',
  link: ROUTE_CONSTANTS.DASHBOARD.REPORTS.FEEDBACKS.ROOT.ABSOLUTE,
  badgeTooltip: 'Feedback Report',
  icon: AssessmentIcon,
  permissionKey: 'REPORT:FEEDBACK'
}

// ***** dashboards routes ***** //

const analyticsRoute = {
  categorySlug: 'dashboards',
  name: 'Analytics',
  link: ROUTE_CONSTANTS.DASHBOARD.ANALYTICS.ABSOLUTE,
  badgeTooltip: 'Analytics',
  icon: DashboardCustomizeOutlined,
  permissionKey: 'KEY_TAG:READ'
}



// ***** store front routes *****
const collectionRoute = {
  categorySlug: 'store_front',
  name: 'Collection',
  link: ROUTE_CONSTANTS.DASHBOARD.STORE_FRONT.COLLECTION.ROOT.ABSOLUTE,
  badgeTooltip: 'Collection',
  icon: PeopleAltIcon,
  permissionKey: 'GET_COLLECTION_LIST'
}
const slideRoute = {
  categorySlug: 'store_front',
  name: 'Slide',
  link: ROUTE_CONSTANTS.DASHBOARD.STORE_FRONT.SLIDE.ROOT.ABSOLUTE,
  badgeTooltip: 'Slide',
  icon: PeopleAltIcon,
  permissionKey: 'GET_SLIDER_LIST'
}
const bannerRoute = {
  categorySlug: 'store_front',
  name: 'Banner',
  link: ROUTE_CONSTANTS.DASHBOARD.STORE_FRONT.BANNER.ROOT.ABSOLUTE,
  badgeTooltip: 'Banner',
  icon: PeopleAltIcon,
  permissionKey: 'GET_BANNER_LIST'
}
const appHomeRoute = {
  categorySlug: 'store_front',
  name: 'App Home',
  link: ROUTE_CONSTANTS.DASHBOARD.STORE_FRONT.APP_HOME.ROOT.ABSOLUTE,
  badgeTooltip: 'App Home',
  icon: PeopleAltIcon,
  permissionKey: 'GET_HOMEPAGE_LIST'
}

// ***** system routes  *****
const settingRoute = {
  categorySlug: 'administration',
  name: 'Setting',
  link: ROUTE_CONSTANTS.DASHBOARD.SYSTEM.SETTING.ROOT.ABSOLUTE,
  badgeTooltip: 'Setting',
  icon: PeopleAltIcon,
  permissionKey: 'GET_SETTINGS'
}
const staticPagesRoute = {
  categorySlug: 'administration',
  name: 'Static Pages',
  link: ROUTE_CONSTANTS.DASHBOARD.SYSTEM.STATIC_PAGES.ROOT.ABSOLUTE,
  badgeTooltip: 'Static Pages',
  icon: PeopleAltIcon,
  permissionKey: 'GET_STATIC_PAGE_LIST'
}

const RoleGroupRoute = {
  categorySlug: 'administration',
  name: 'Role Group',
  link: ROUTE_CONSTANTS.DASHBOARD.ADMINISTRATION.ROLE_GROUP.ROOT.ABSOLUTE,
  badgeTooltip: 'Permissions',
  icon: Groups3Icon,
  permissionKey: 'ROLE_GROUP:READ'
}
const SmsProvidersRoute = {
  categorySlug: 'administration',
  name: 'SMS Providers',
  link: ROUTE_CONSTANTS.DASHBOARD.ADMINISTRATION.SMS_PROVIDERS.ROOT.ABSOLUTE,
  badgeTooltip: 'SMS Providers',
  icon: MessageOutlined,
  permissionKey: 'CONFIGURATION:SENDSMS'
}


// ***** IMPORTANT NOTE ABOUT EXPOSING ROUTES ****  orders of defining routes inside export object has direct effect on their orders in sidebar
export const sidebarRoutesObjectsCashier = {
  // *** user routes 
  adminsRoute,
  bookingsRoute,
  customerRoute,

  landLordsRoute,
  checkInOutRoute,
  incomeReportRoute,
  CloseShiftReportRoute,
  DriverReportRoute,
  KeyTagReportRoute,
  HourWiseReportRoute,
  IncomeSummaryReportRoute,
  VoucherReportRoute,
  ServiceReportRoute,
  FeedbackReportRoute,

  carManufactorerRoute,
  carModelRoute,
  parkingLocationRoute,
  parkingFloorRoute,
  parkingBlockRoute,
  keyTagRoute,
  SubscriptionRoute,
  AdvertisementRoute,
  companiesRoute,
  servicesRoute,

  analyticsRoute,
  // *** system routes 
  settingRoute,
  staticPagesRoute,
  RoleGroupRoute,
  SmsProvidersRoute,

  // *** store front routes 
  collectionRoute,
  slideRoute,
  bannerRoute,
  appHomeRoute,
}